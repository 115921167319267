import React from "react";
import {
  GooglePlayButton,
  AppStoreButton,
  ButtonsContainer,
} from "react-mobile-app-button";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-left">
          <div className="footer-text">
            © {new Date().getFullYear()} Flyr AU. All rights reserved.
          </div>
          <div className="footer-contact">
            <a href="mailto:flyr@proflow.ai" className="footer-contact-button">
              Contact Us
            </a>
          </div>
        </div>

        <div className="footer-right">
          <div className="footer-app-buttons">
            <ButtonsContainer>
              <AppStoreButton
                url={"https://apps.apple.com/au/app/flyr-au/id6742386796"}
                theme={"dark"}
                className={"custom-style"}
              />
              <GooglePlayButton
                url={
                  "https://play.google.com/store/apps/details?id=com.proflowai.flyrapp"
                }
                theme={"dark"}
                className={"custom-style"}
              />
            </ButtonsContainer>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
