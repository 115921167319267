import React from "react";
import { Link, useLocation } from "react-router-dom";

const Navigation = () => {
  const location = useLocation();

  return (
    <nav className="navigation">
      <div className="nav-container">
        <div className="logo-area">
          <img
            src="icon_fp2_android.png"
            alt="Flyr icon"
            className="nav-logo"
          />
        </div>
        <div className="nav-links">
          <Link
            to="/"
            className={`nav-link ${location.pathname === "/" ? "active" : ""}`}
          >
            For Clients
          </Link>
          <Link
            to="/distribute"
            className={`nav-link ${
              location.pathname === "/distribute" ? "active" : ""
            }`}
          >
            For Distributors
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
