import React from "react";
import {
  GooglePlayButton,
  AppStoreButton,
  ButtonsContainer,
} from "react-mobile-app-button";

const DistributorContent = () => {
  return (
    <main className="main-content">
      {/* Initial Content */}
      <div className="initial-content">
        <h1 className="main-heading">
          Flyer distribution network for distributors
        </h1>

        <h2 className="sub-heading">
          Designed for professional distributors.
          <br />
          Track your deliveries, manage routes, and maximise your earnings.
        </h2>

        <ButtonsContainer>
          <AppStoreButton
            url={"https://apps.apple.com/au/app/flyr-au/id6742386796"}
            theme={"dark"}
            className={"custom-style"}
          />
          <GooglePlayButton
            url={
              "https://play.google.com/store/apps/details?id=com.proflowai.flyrapp"
            }
            theme={"dark"}
            className={"custom-style"}
          />
        </ButtonsContainer>
      </div>

      {/* Section A */}
      <section className="content-section section-b">
        <div className="section-text">
          <h2 className="section-primary-heading">You choose when</h2>
          <p className="section-secondary-heading">
            Easily manage when you are available to deliver
          </p>
        </div>
        <img
          src="/assets/preonboarding_calendar.png"
          alt="Proof of Delivery"
          className="section-image"
        />
      </section>

      {/* Section B */}
      <section className="content-section section-a">
        <img
          src="/assets/preonboarding_walk.png"
          alt="GPS Tracking"
          className="section-image"
        />
        <div className="section-text">
          <h2 className="section-primary-heading">Stay fit while earning</h2>
          <p className="section-secondary-heading">
            Turn your daily steps into income with our active delivery network
          </p>
        </div>
      </section>

      {/* Section C */}
      <section className="content-section section-c">
        <div className="section-text">
          <h2 className="section-primary-heading">Above market pay</h2>
          <p className="section-secondary-heading">
            Competitive rates that reward your reliability and efficiency
          </p>
        </div>
        <img
          src="/assets/onboarding_areas.png"
          alt="Payment Tracking"
          className="section-image"
        />
      </section>

      {/* Section D */}
      <section className="content-section section-c">
        <div className="section-text">
          <h2 className="section-primary-heading">
            Clarity on what's expected
          </h2>
          <p className="section-secondary-heading">
            Clear delivery zones, straightforward tasks, and transparent
            tracking
          </p>
        </div>
        <img
          src="/assets/preonboarding_signup.png"
          alt="Payment Tracking"
          className="section-image"
        />
      </section>

      {/* Section E */}
      <section className="content-section section-e">
        <div className="section-text">
          <h2 className="section-primary-heading">Simple rules</h2>
          <p className="section-secondary-heading">
            Our values are simple. They can be summarised as "just do the right
            thing".
          </p>
        </div>
        <img
          src="/assets/our_commitment.png"
          alt="Proof of Delivery"
          className="section-image"
        />
      </section>
    </main>
  );
};

export default DistributorContent;
