import React from "react";
import {
  GooglePlayButton,
  AppStoreButton,
  ButtonsContainer,
} from "react-mobile-app-button";

const MainContent = () => {
  return (
    <main className="main-content">
      {/* Initial Content */}
      <div className="initial-content">
        <h1 className="main-heading">
          Flyer distribution for Australian businesses
        </h1>

        <h2 className="sub-heading">
          Designed for small and medium-sized businesses.
          <br />
          Track, verify and optimise your marketing campaigns.
        </h2>

        <ButtonsContainer>
          <AppStoreButton
            url={"https://apps.apple.com/au/app/flyr-au/id6742386796"}
            theme={"dark"}
            className={"custom-style"}
          />
          <GooglePlayButton
            url={
              "https://play.google.com/store/apps/details?id=com.proflowai.flyrapp"
            }
            theme={"dark"}
            className={"custom-style"}
          />
        </ButtonsContainer>
      </div>

      {/* Section A - New main section */}
      <section className="content-section section-a">
        <img
          src="/assets/onboarding_areas.png"
          alt="Campaign Area Selection"
          className="section-image"
        />
        <div className="section-text">
          <h2 className="section-primary-heading">You choose where</h2>
          <p className="section-secondary-heading">
            Using our state-of-the-art technology, easily select the houses you
            want to include in your campaign
          </p>
        </div>
      </section>

      {/* Section B */}
      <section className="content-section section-b">
        <img
          src="/assets/preonboarding_walk.png"
          alt="GPS Tracking"
          className="section-image"
        />
        <div className="section-text">
          <h2 className="section-primary-heading">Track and verify</h2>
          <p className="section-secondary-heading">
            GPS-based tracking ensures flyers reach their intended destination,
            every single delivery
          </p>
        </div>
      </section>

      {/* Section C */}
      <section className="content-section section-c">
        <img
          src="/assets/preonboarding_calendar.png"
          alt="Real-time Tracking"
          className="section-image"
        />
        <div className="section-text">
          <h2 className="section-primary-heading">Real-time insights</h2>
          <p className="section-secondary-heading">
            Monitor distributions live and get detailed reports
          </p>
        </div>
      </section>

      {/* Section D */}
      <section className="content-section section-d">
        <div className="section-text">
          <h2 className="section-primary-heading">Manage your campaigns</h2>
          <p className="section-secondary-heading">
            Easily keep track of what has been done and when
          </p>
        </div>
        <img
          src="/assets/preonboarding_signup.png"
          alt="Campaign Management"
          className="section-image"
        />
      </section>

      {/* Section E */}
      <section className="content-section section-e">
        <img
          src="/assets/onboarding_certified.png"
          alt="Our Commitment"
          className="section-image"
        />
        <div className="section-text">
          <h2 className="section-primary-heading">Our commitment</h2>
          <p className="section-secondary-heading">
            Dedicated support and continuous improvements to help your business
            grow
          </p>
        </div>
      </section>
    </main>
  );
};

export default MainContent;
